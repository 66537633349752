.deposit_banner_image_christmas {
    width: 140px;
    position: relative;
    float: left;
    z-index: -1;
    margin-left: 15px;

    @media (max-width: 768px) {
        width: 120px;
        margin-left: 10px;
    }
}