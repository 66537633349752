.email_opt_in_form_email {
    font-weight: 600;
}

.email_opt_in_form {
    .form-check-label {
        font-size: 14px;
        color: $font-mid;
        margin-bottom: 10px;
    }
}