.deposit_banner_image_wolf_gold {
    width: 110px;
    position: relative;
    float: left;
    z-index: -1;
    margin-left: 20px;

    @media (max-width: 768px) {
        width: 90px;
        margin-left: 15px;
    }
}