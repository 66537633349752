.home_banner_container_theme_vip_10 {
    background-image: url(https://spt-assets.b-cdn.net/orozino/themes/vip_10/home_banner_background.jpg);
    background-position: center;
}

.theme_vip_banner_container {
    background-image: url(https://spt-assets.b-cdn.net/orozino/themes/vip_10/home_banner_loggedin_background.jpg);
    background-position: center top;
}

.theme_vip_banner_image {
    @media (max-width: 768px) {
        height: auto;
        max-height: 100px;
        width: auto;
        margin-bottom: 0px;
        margin-top: -20px;
    }
}

.theme_vip_banner_image_text_container {
    position: absolute;
    text-align: center;
    bottom: 13%;
    width: 100%;
}

.theme_vip_banner_image_text {
    color: #FFF;
    margin-bottom: 15px;
    opacity: 0.75;

    @media (max-width: 768px) {
        margin-bottom: 0px;
    }
}

.theme_vip_banner_text_wrapper {
    padding-top: 0px;

    h2 {
        color: #FFF;

        @media (min-width: 769px) {
            text-align: left;
        }
    }
}

.theme_vip_banner_text_list {
    color: #FFF;
    list-style-type: none;
    margin: 0;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 768px) {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    li:before {
        content: "✓";
        margin-right: 7px;
    }

    li {
        font-size: 14px;
        margin-bottom: 5px;
        opacity: 0.75;
    }
}