.home_banner_container_theme_dragon_kingdom {
    background-image: url(https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/home_banner_background.jpg);
    background-position: center;

    @media (max-width: 768px) {
        background-position-x: -120px;
    }
}

.home_banner_images_theme_dragon_kingdom {
    margin-top: 50px;
    max-width: 600px;

    @media (max-width: 1399px) {
        margin-top: 20px;
        max-width: 500px;
    }

    @media (max-width: 768px) {
        margin-top: 20px;
        max-width: 340px;
        height: auto;
    }
}