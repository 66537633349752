.home_banner_container_theme_crazy_time {
    background-image: url(https://spt-assets.b-cdn.net/orozino/themes/crazy_time/home_banner_background.jpg);
    background-position: center;
}

.home_banner_images_theme_crazy_time {
    z-index: 0;

    @media (max-width: 1399px) {
        max-width: 500px;
    }
}

.home_banner_images_theme_crazy_time_2 {
    height: 422px;
    left: -94px;
    bottom: -31px;
    position: absolute;
    z-index: 1;

    @media (max-width: 1399px) {
        height: 350px;
        left: -40px;
        bottom: 4px;
    }

    @media (max-width: 768px) {
        height: 220px;
        left: -44px;
        bottom: -13px;
    }
}