.deposit_banner_image_crazy_time {
    width: 120px;
    position: relative;
    float: left;
    z-index: -1;
    margin-left: 10px;

    @media (max-width: 768px) {
        width: 100px;
        margin-left: 0px;
    }
}