.deposit_banner_image_vip_10 {
    width: 120px;
    position: relative;
    float: left;
    z-index: -1;
    margin-left: 10px;

    @media (max-width: 768px) {
        width: 120px;
        margin-left: 6px;
    }
}

.deposit_banner_content_background_vip_10 {
    padding: 18px 30px 15px 10px;
}

.deposit_banner_content_subtitle_vip_10 {
    font-size: 14px;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    span {
        color: $primary;
        font-weight: 500;
    }
}