.brand {
    margin-right: 1.5rem;
    margin-left: 0.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;

    @media (max-width: 767px) {
        margin-right: 0rem;
        margin-left: 0.25rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.logo {
    height: 43px;

    @media (max-width: 576px) {
        height: 34px;
        margin-top: 5px;
    }
}

.navbar-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1004;
    transition: all 0.1s ease-in;
    background-color: $foreground;

    @media (max-width: 999px) {
        padding-top: 6px;
        padding-bottom: 7px;
    }

    &-logged-in-mobile {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1004;
        transition: all 0.1s ease-in;
        background-color: $foreground;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
    }
}

.navbar-name-wrapper {
    width: 130px;
}

.navbar-balance-mobile-wrapper {
    width: 130px;
    justify-content: flex-end;
}

.navbar-name {
    color: $font-full;
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navbar-balance-mobile {
    text-align: right;
    color: $font-full !important;
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none !important;
}

.navbar-link {
    margin: 2px 7px 0;
    font-size: 14px;
    font-weight: 500;
    color: $font-mid;
    text-decoration: none;
    
    padding: 4px 10px;
    border-radius: 15px;
    border: 2px solid transparent;

    transition: all 0.3s ease-out;

    &:hover,
    &:active {
        color: $font-mid;
        background: $background;
    }

    &-active,
    &-active:hover,
    &-active:active {
        font-weight: 600;
        color: $font-full;
    }
}

.navbar-bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 0px 10px 0px;
    background: $foreground;
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);

    transition: bottom 0.3s ease-in;
    z-index: 1004;

    &-hidden {
        bottom: -100px;
    }

    &-app {
        padding: 0px 10px 25px;
    }
}

.navbar-bottom > div > div > a:first-child {
    border-radius: $border-radius 0 0 $border-radius;
}

.navbar-bottom > div > div > a:last-child {
    border-radius: 0 $border-radius $border-radius 0;
}

.navbar-bottom-wrapper {
    background: $foreground;
    border-radius: $border-radius;
}

.navbar-bottom-link,
.navbar-bottom-link:hover,
.navbar-bottom-link:active,
.navbar-bottom-link:focus {
    position: relative;
    font-size: 19px;
    line-height: 1.125rem;
    padding: 15px 0px 25px;
    flex-grow: 1;
    background-color: $foreground;
    color: $font-full;
    border: 0px solid transparent;
    outline: none;
    border-radius: 0;
    text-align: center;
}

.navbar-bottom-link:active {
    background-color: $foreground;
    border: 0px solid transparent;
    outline: none;
}

.navbar-bottom-link:active > * {
    transform: scale(0.95);
}

.navbar-bottom-link-logo>img {
    width: 20px;
    height: 20px;
}

.navbar-bottom-link.navbar-bottom-link-active::after {
    content: ('');
    position: absolute;
    left: calc(50% - 15px);
    top: 0;
    width: 30px;
    height: 5px;
    border-radius: 0 0 5px 5px;
    background-color: $primary;
    // box-shadow: 0 0 10px 2px $primary;
}

.navbar-bottom-link-deposit,
.navbar-bottom-link-deposit:hover,
.navbar-bottom-link-deposit:active,
.navbar-bottom-link-deposit:focus {
    // color: #d93744;
    flex-grow: 1.3;
}

.navbar-bottom-link-deposit-alert {
    position: absolute;
    font-size: 14px;
    top: 9px;
    left: 24px;
    color: #d93744;
}

/************************/
/* Navigation balance */
.navbar-balance,
.navbar-balance:focus {
    margin: 0px 10px;
    color: $font-full;
    border-color: $primary;

    transition: all 0.2s ease-in;
}

.navbar-balance:hover,
.navbar-balance:active {
    background-color: $foreground;
    color: $font-full;
    border-color: $primary;
}

.btn-navbar {
    padding-top: 13px;
    padding-bottom: 13px;

    @media (max-width: 991px) {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 95px;
    }
}