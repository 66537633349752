.cashier_offcanvas_header {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    padding: 15px;
    z-index: 1;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 991px) {
        padding: 10px;
    }
}

.cashier_offcanvas_header_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.cashier_offcanvas_header_title {
    font-size: 18px;
    font-weight: 500;
    color: $font-full;
    margin-bottom: 0;

    @media (max-width: 991px) {
        font-size: 16px;
    }
}

.cashier_offcanvas_header .player-balance {
    font-size: 18px;
    font-weight: 500;
    color: $font-full;

    @media (max-width: 991px) {
        font-size: 16px;
    }
}

.cashier_offcanvas_body {
    padding-top: 64px;

    @media (max-width: 991px) {
        padding-top: 54px;
    }
}

.deposit-section,
.deposit-container,
.withdraw-container,
.withdraw-section {
    height: calc(100vh - 74px);

    @media (max-width: 991px) {
        height: calc(100vh - 64px);
    }
}