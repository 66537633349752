.registration_banner_content_title_egypt_0 {
    font-size: 35px;
    line-height: 41px;

    @media (max-width: 768px) {
        font-size: 26px;
        line-height: 30px;
    }
}

.registration_banner_content_subtitle_egypt_0 {
    white-space: normal;
    
    @media (max-width: 768px) {
        padding: 0 10px;
    }
}